import "./index.js";
import "./App.css";
import Panel from "./Panel";
import React from "react";

function App() {

  return (
    <div>
      <Panel />
    </div>
  );
}

export default App;
