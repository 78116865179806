import React from "react";
import { createRoot } from 'react-dom';
import "./index.css";
import App from "./App";


const root = document.querySelector("#root");
const rootElement = createRoot(root);
rootElement.render(

  <App />

);